<template>
  <div
    class="parking"
    v-if="initShow"
    v-loading="loading"
    element-loading-text="加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="parkingpay">
      <div class="license-plate" v-if="parkData.plateNo">
        <div class="number">{{ this.parkData.plateNo }}</div>
      </div>
      <el-form ref="form" :model="form" class="form">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>{{ this.parkData.roadName }}</span>
            <span style="float: right">{{ this.parkData.berthNo }}</span>
          </div>
          <div class="details text item">
            <div class="cash">
              <div style="font-size: 26px">
                <i style="font-size: 14px">￥</i>
                {{ (this.parkData.totalPay * 1) | rounding }}
              </div>
              <div style="font-size: 12px">实际费用以车辆出场为准</div>
            </div>
            <div class="etc" v-if="parkData.etcPay != null && parkData.etcPay == true ? true : false">
              <span>该费用已从ETC扣取,实际费用以车辆出场为准</span>
            </div>
            <div class="message">
              <div>驶入时间</div>
              <div>{{ this.parkData.startTime }}</div>
            </div>
            <div class="message">
              <div>取证时间</div>
              <div>{{ this.parkData.uplodaPdaTime }}</div>
            </div>
            <div class="message">
              <div>驶出时间</div>
              <div>{{ this.parkData.stopTime }}</div>
            </div>
            <div class="message">
              <div>扫码时间</div>
              <div>{{ parkData.scanDateTime }}</div>
            </div>
            <div class="message">
              <div>停车时长</div>
              <div>{{ this.parkData.parkTime }}</div>
            </div>
            <div class="message">
              <div>已付金额</div>
              <div style="color: red">￥{{ (this.parkData.alreadPay * 1) | rounding }}</div>
            </div>
            <!-- <div class="message">
              <div>优惠金额</div>
              <div style="color: red">￥{{ checkCouponList.id ? checkCouponList.couponValue : 0.0 }}</div>
            </div> -->
            <div class="message">
              <div>待付金额</div>
              <div style="color: red">￥{{ (this.paymentAmount * 1) | rounding }}</div>
            </div>
          </div>
        </el-card>
        <div style="display: flex; justify-content: center; color: red; margin: 20px 0; font-size: 16px" v-if="isBrake">
          请确保驻车器下降完成再驶离！
        </div>
        <!-- 实时订单 -->
        <el-card class="box-card" v-if="realParkRecord.parkId">
          <div class="arrears" style="margin: -10px 0">
            <div>实时订单</div>
            <div style="font-size: 14px; color: #4395ff" @click="realParkDetail">详情</div>
          </div>
          <div class="box-card-de">
            <div slot="header" class="clearfix-de">
              <span>泊位号:{{ realParkRecord.berthNos }}</span>
              <el-checkbox-group style="float: right" v-model="realParkRecordCheck" @change="handleRealPark">
                <!-- 加上<br />可以不展示文字 -->
                <el-checkbox :label="realParkRecord"><br /></el-checkbox>
              </el-checkbox-group>
            </div>
            <div class="text-de item">
              <div class="message-de">驶入时间：{{ realParkRecord.parkingTime }}</div>
              <div class="message-de">路段：{{ realParkRecord.roadName }}</div>
              <div class="message-de">
                待缴金额：
                <span style="color: red"> ￥{{ (realParkRecord.shouldPay * 1) | rounding }} </span>
              </div>
            </div>
          </div>
        </el-card>
        <!-- 欠费 -->
        <el-card class="box-card" v-if="!this.form.arreas">
          <div class="arrears" style="margin: -10px 0">
            <div>
              历史欠费（￥
              <span>
                {{ (this.parkData.totalArrea * 1) | rounding }}
              </span>
              /
              <span>{{ this.parkData.arreaCount }}</span>
              笔）
            </div>
            <div
              style="font-size: 14px; color: #4395ff"
              @click="toDetail"
              :class="this.detailsedit ? 'el-icon-arrow-up' : ''"
            >
              {{ this.detailsedit ? "收起" : "详情" }}
            </div>
          </div>
          <div v-show="detailsedit" class="box-card-de" v-for="(park, index) in this.parkData.arreas" :key="park.id">
            <div slot="header" class="clearfix-de">
              <span>泊位号:{{ park.berthNo }}</span>
              <el-checkbox-group style="float: right" v-model="checkedPark" @change="change">
                <!-- 加上<br />可以不展示文字 -->
                <el-checkbox :label="itemIds[index]"><br /></el-checkbox>
              </el-checkbox-group>
            </div>
            <div class="text-de item" @click="toDepDetail(park.id)">
              <div class="message-de">驶入时间：{{ park.parkingRecord.parkingTime }}</div>
              <div class="message-de">驶出时间：{{ park.parkingRecord.parkoutTime }}</div>
              <div class="message-de">路段：{{ park.parkingRecord.roadName }}</div>
              <div class="message-de">
                欠费金额：
                <span style="color: red"> ￥{{ (park.arrear * 1) | rounding }} </span>
              </div>
              <!-- <div class="message-de">
                核销金额： ￥{{ (park.cancelArrea * 1) | rounding }}
              </div> -->
            </div>
          </div>
        </el-card>

        <!-- <el-card class="box-card rule">
          <div class="arrears" style="margin-top: -10px">
            <div>收费规则</div>
            <div><i class="el-icon-arrow-right"></i></div>
          </div>
          <div class="line"></div>
          <div class="arrears" style="margin-bottom: -10px">
            <div>用户协议</div>
            <div><i class="el-icon-arrow-right"></i></div>
          </div>
        </el-card> -->
        <div class="coupon">
          <van-cell
            title="优惠券"
            is-link
            :value="
              checkCouponList.type === 1
                ? '减金额'
                : checkCouponList.type === 2
                ? '减时长'
                : checkCouponList.type === 3
                ? '百分比'
                : checkCouponList.type === 4
                ? '全免券'
                : checkCouponList.type === 5
                ? '全额免'
                : checkCouponList.type == 6
                ? '折扣券'
                : '无'
            "
            @click="couponEdit"
          >
            <template #title>
              <span class="custom-title">优惠券</span>
              <van-tag
                plain
                type="danger"
                style="margin-left: 5px"
                v-if="allCouponList.length && checkCouponList.id === bestCoupon.id ? true : false"
              >
                已选择推荐优惠
              </van-tag>
            </template>
          </van-cell>
        </div>
        <van-popup v-model="couponshow" closeable round position="bottom" :style="{ height: '60%' }" class="coupon-box">
          <div v-if="allCouponList.length">
            <div class="coupon-title">优惠券</div>
            <van-divider :hairline="false" />
            <div style="font-size: 15px; color: #333333; margin-bottom: 10px">
              您已选择{{ couponCheck !== "" ? 1 : 0 }}张优惠券
              <!-- <span v-if="couponCheck !== ''">
                ，可抵扣<span style="color: #eb5757">
                  {{ checkCouponList.type === 1 ? "￥" : "" }}
                  {{ checkCouponList.value }}
                </span>
              </span> -->
            </div>
            <van-radio-group v-model="couponCheck" style="margin: 10px 0; font-size: 15px">
              <van-radio name="" checked-color="#FFA763">不使用优惠券</van-radio>
            </van-radio-group>
            <div class="coupontype-box">
              <div class="coupontype" v-for="(item, index) in allCouponList" :key="index">
                <div class="left">
                  <div style="color: #ffffff; font-size: 30px; font-weight: 400">
                    <i style="font-size: 12px" v-if="item.type === 1">￥</i>{{ item.couponValue }}
                    <i style="font-size: 12px" v-if="item.type === 6">折</i>
                    <i style="font-size: 12px" v-if="item.type === 2">分钟</i>
                  </div>
                  <div class="couponname">
                    {{
                      item.type === 1
                        ? "减金额"
                        : item.type === 2
                        ? "减时长"
                        : item.type === 3
                        ? "百分比"
                        : item.type === 4
                        ? "全免券"
                        : item.type === 5
                        ? "全额免"
                        : "折扣券"
                    }}
                  </div>
                </div>
                <div class="right">
                  <div class="top-cotent">
                    <div style="font-size: 15px; font-weight: 400; letter-spacing: 1px; color: #333333">
                      {{ item.couponName }}
                    </div>
                    <div class="rule" @click="handleRule(item)">
                      规则
                      <div class="el-icon-arrow-right"></div>
                    </div>
                  </div>
                  <div class="checkbox" style="margin-top: 5px; float: right">
                    <van-radio-group v-model="couponCheck">
                      <van-radio :name="item.id" checked-color="#FFA763"></van-radio>
                    </van-radio-group>
                  </div>
                  <div class="usetime"></div>
                  <div class="usetime2">有效期至:{{ item.endTime }}</div>
                </div>
              </div>
            </div>
          </div>
          <van-empty v-else description="暂无可用优惠券" />
          <div class="coupon-define" @click="couponsumbit">确定</div>
        </van-popup>

        <div class="tab-bar">
          <el-checkbox v-model="checkAll" @change="handleCheckAllChange" :disabled="true"> 全选 </el-checkbox>
          <div>
            <span>合计：</span>
            <span style="color: red"> ￥{{ (shouldPayPreCPU * 1) | rounding }} </span>
          </div>
          <el-button
            :style="{
              'background-color': '#409eff',
            }"
            @click="payWaysettlement"
            v-preventReClick="3000"
          >
            结算
          </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { Dialog } from "vant";
import dayjs from "dayjs";
import * as math from "mathjs";

export default {
  name: "Parkingpay",
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      loading: false,
      initShow: false,
      checkAll: true,
      // 选择的值
      checkedPark: [],
      // 详情控制
      detailsedit: false,
      form: {},
      // 停车数据
      parkData: {},
      // 结算按钮禁用控制
      isOn: false,
      // 欠费的列表
      itemIds: [],
      // 保存的原本金额
      cash: 0,
      // 代付金额不改变
      paymentAmount: 0,
      savePaymentAmount: 0,
      // 欠费的总金额
      arrearPay: 0,
      // 展示优惠券
      couponshow: false,
      // 选择优惠券
      couponCheck: "",
      // 所有优惠券列表
      allCouponList: [],
      // 选中的优惠券
      checkCouponList: {},
      // 最佳优惠券
      bestCoupon: {},
      // 实时订单数据
      realParkRecord: {},
      realParkRecordCheck: [],
      checkCountList: [],
      inrealParkList: [],
      allCountLength: null,
      // 驻车器
      isBrake: localStorage.getItem("isBrake"),
      domain: process.env.VUE_APP_TEST_DOMAIN,
    };
  },
  mounted() {
    this.getList();
  },
  destroyed() {
    this.clear();
  },
  watch: {
    couponCheck: {
      handler(val) {
        if (val !== "") {
          let newList = [];
          newList = this.allCouponList.filter((item) => item.id === val);
          this.checkCouponList = newList[0];
        } else {
          this.checkCouponList = {};
        }
      },
    },
  },
  computed: {
    shouldPayPreCPU() {
      let realFee = this.realParkRecordCheck.length ? this.realParkRecordCheck[0].shouldPay : 0;
      if (this.realParkRecord.parkId) {
        if (this.checkCouponList.id) {
          // 选中优惠券后加上其他订单金额
          let couponPay = this.checkCouponList.id ? this.checkCouponList.afterFee : 0;
          // this.arrearPay + this.savePaymentAmount + couponPay;
          return math.add(
            math.bignumber(this.arrearPay),
            math.bignumber(this.savePaymentAmount),
            math.bignumber(couponPay)
          );
        } else {
          // 没选中初始化金额  this.arrearPay + this.savePaymentAmount + realFee;
          return math.add(
            math.bignumber(this.arrearPay),
            math.bignumber(this.savePaymentAmount),
            math.bignumber(realFee)
          );
        }
      } else {
        if (this.checkCouponList.id) {
          // 选中优惠券后加上其他订单金额
          let couponPay = this.checkCouponList.id ? this.checkCouponList.afterFee : 0;
          // this.arrearPay + couponPay;
          return math.add(math.bignumber(this.arrearPay), math.bignumber(couponPay));
        } else {
          // 没选中初始化金额  this.arrearPay + this.savePaymentAmount + realFee;
          return math.add(
            math.bignumber(this.arrearPay),
            math.bignumber(this.savePaymentAmount),
            math.bignumber(realFee)
          );
        }
      }
    },
  },
  methods: {
    // 实时订单详情
    realParkDetail() {
      let { realParkRecord } = this;
      this.$router.push({
        name: "Realparkrecord",
        params: realParkRecord,
      });
    },
    // 实时订单选择
    handleRealPark(val) {
      if (val.length) {
        this.realParkRecordCheck = val;
        this.change(this.checkCountList);
        // 选中优惠券计算金额
        this.checkCouponList.id && this.couponsumbit();
      } else {
        this.realParkRecordCheck = [];
        this.checkAll = false;
        // 取消勾选后重新计算
        if (this.checkCouponList.id) {
          // 不选实时订单重置金额
          this.change(this.checkCountList);
          this.couponCheck = "";
          this.checkCouponList = {};
        }
      }
    },
    // 规则
    handleRule(item) {
      if (item.useExplain !== null) {
        Dialog.alert({
          message: `${item.useExplain}`,
        }).then(() => {
          // on close
        });
      } else {
        Dialog.alert({
          message: `暂无详细使用规则，最终解释权归属商家`,
        }).then(() => {
          // on close
        });
      }
    },
    // 优惠券选择确定
    couponsumbit() {
      if (this.checkCouponList.id) {
        // 有实时订单就勾选上
        if (this.realParkRecord.parkId && this.realParkRecordCheck.length == 0) {
          this.handleRealPark([this.realParkRecord]);
        }
      }
      this.couponshow = false;
    },
    couponEdit() {
      this.couponshow = true;
    },
    // 优惠券查询
    getCouponList(obj) {
      let { appId, itemId, plateNo, monthFee, startTime, endTime, parkRecordId, scanningTime, type } = obj;
      this.$axios
        .post(`${this.domain}/api/charge/bestOffer`, {
          parkRecordId,
          appId,
          itemId,
          plateNo,
          monthFee,
          startTime,
          endTime,
          scanningTime,
        })
        .then((res) => {
          if (res.data.data) {
            if (res.data.data.ticketInfos.length) {
              this.allCouponList = res.data.data.ticketInfos;
              this.bestCoupon = res.data.data.ticketInfo;
              if (type) {
                let newList = res.data.data.ticketInfos.filter((item) => item.id == this.couponCheck);
                this.checkCouponList = newList[0];
              } else {
                this.checkCouponList = res.data.data.ticketInfo;
                this.couponCheck = res.data.data.ticketInfo.id;
              }
              this.couponsumbit();
            }
          }
        });
    },
    // 小时转xx小时xx分钟
    convertTime(time) {
      const hours = time * 1;
      const date = dayjs()
        .hour(Math.floor(hours))
        .minute(Math.round((hours % 1) * 60));
      return { a: date.format("HH"), b: date.format("mm") };
    },
    async getList() {
      let parkRecordId = localStorage.getItem("parkRecordId");
      let itemId = localStorage.getItem("itemId");
      await this.$axios
        .post(`${this.domain}/api/charge/charging`, {
          parkRecordId,
        })
        .then((res) => {
          this.parkData = res.data.data;
          this.paymentAmount = res.data.data.shouldPay;
          this.savePaymentAmount = res.data.data.shouldPay;
          this.cash = res.data.data.shouldPay;
          if (res.data.data.arreas) {
            this.itemIds = res.data.data.arreas.map((item) => ({
              id: item.id,
              arrear: item.arrear,
              parkRecordId: item.parkingRecord.parkId,
            }));
            // 保存欠费列表
            this.checkedPark = this.itemIds;
            this.checkCountList = this.itemIds;
            this.parkData.shouldPay = this.cash;
            // 欠费的金额初始化
            this.arrearPay = 0;
            let money = this.checkedPark.reduce((accumulator, currentValue) => {
              const valueToAdd = currentValue.arrear;
              return math.add(math.bignumber(accumulator), math.bignumber(valueToAdd));
            }, 0);
            this.arrearPay = Number(money);
            this.parkData.shouldPay = Number(money);
          }
          // 判断是否有使用优惠券的参数
          let couponRecordId;
          let startTimeRecord;
          let stopTimeRecord;
          let monthFeeRecord;
          if (res.data.data.realParkRecord && res.data.data.realParkRecord !== null) {
            // 处理泊位号
            res.data.data.realParkRecord.berthNos = JSON.parse(res.data.data.realParkRecord.berthNos).join();
            // 保存实时订单
            this.realParkRecord = res.data.data.realParkRecord;
            // 初始化选择状态
            this.realParkRecordCheck.push(res.data.data.realParkRecord);
            // 固定保存选择为后续做全选反全选
            this.inrealParkList.push(res.data.data.realParkRecord);

            couponRecordId = res.data.data.realParkRecord.parkId;
            startTimeRecord = res.data.data.realParkRecord.startTime;
            stopTimeRecord = res.data.data.realParkRecord.endTime;
            monthFeeRecord = res.data.data.realParkRecord.shouldPay;
          } else {
            couponRecordId = parkRecordId;
            startTimeRecord = res.data.data.startTime;
            stopTimeRecord = res.data.data.stopTime;
            monthFeeRecord = res.data.data.shouldPay;
          }
          // 优惠券
          this.getCouponList({
            appId: res.data.data.appId,
            itemId,
            parkRecordId: couponRecordId,
            plateNo: res.data.data.plateNo,
            startTime: startTimeRecord,
            endTime: stopTimeRecord,
            monthFee: monthFeeRecord,
            scanningTime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
            type: 0,
          });
          this.initShow = true;
        });
    },
    change(value) {
      // 保存选中数据
      this.checkCountList = value;
      // 总长度
      let checkedCount = this.itemIds.length + this.inrealParkList.length;
      // 选中长度是否等于总长度
      this.checkAll = checkedCount === value.length + this.realParkRecordCheck.length;
      // 每次选中先等于初始值
      this.parkData.shouldPay = this.cash;
      // 欠费的金额初始化
      this.arrearPay = 0;
      let money = value.reduce((accumulator, currentValue) => {
        const valueToAdd = currentValue.arrear;
        return math.add(math.bignumber(accumulator), math.bignumber(valueToAdd));
      }, 0);
      this.arrearPay = Number(money);
      this.parkData.shouldPay = Number(money);
    },
    // 全选
    handleCheckAllChange(val) {
      this.checkedPark = val ? this.itemIds : [];
      this.checkCountList = val ? this.itemIds : [];
      this.parkData.shouldPay = this.cash;
      // 欠费的金额初始化
      this.arrearPay = 0;
      let money = this.checkedPark.reduce((accumulator, currentValue) => {
        const valueToAdd = currentValue.arrear;
        return math.add(math.bignumber(accumulator), math.bignumber(valueToAdd));
      }, 0);
      this.arrearPay = Number(money);
      this.parkData.shouldPay = Number(money);

      if (val && this.realParkRecord.parkId) {
        this.realParkRecordCheck = [];
        this.realParkRecordCheck.push(this.realParkRecord);
      } else {
        this.realParkRecordCheck = [];
      }
    },
    // 定时刷新数据函数
    dataRefreh() {
      // 计时器正在进行中，退出函数
      if (this.intervalId != null) {
        return;
      }
      // 计时器为空，操作
      this.intervalId = setInterval(() => {
        this.isOn = true; //加载数据函数
        alert("订单已超时，请重新扫码");
      }, 60000);
    },
    // 停止定时器
    clear() {
      clearInterval(this.intervalId); //清除计时器
      this.intervalId = null; //设置为null
    },
    payRequest() {
      let openId = localStorage.getItem("openId");
      let userId = localStorage.getItem("alipayuserId");
      let appId = localStorage.getItem("appId");
      let itemId = localStorage.getItem("itemId");
      let pdaNo = localStorage.getItem("pdaNo");
      let realFee = this.realParkRecordCheck.length ? this.realParkRecordCheck[0].shouldPay : 0;
      let realNos =
        this.realParkRecordCheck.length && this.realParkRecordCheck[0].shouldPay !== 0
          ? this.realParkRecordCheck[0].parkId
          : null;
      this.$axios.get(`${this.domain}/api/jinhua/select/${appId}/${itemId}`).then((res) => {
        if (res.data.data !== null) {
          this.loading = true;
          let ids = [];
          let nos = "";
          let arrea_fee = null;
          if (this.checkedPark.length) {
            this.checkedPark.forEach((item) => {
              ids.push(item.id);
            });
            nos = ids.join(",");
            arrea_fee = this.checkedPark.reduce((accumulator, currentValue) => {
              const valueToAdd = currentValue.arrear;
              return math.add(math.bignumber(accumulator), math.bignumber(valueToAdd));
            }, 0);
            arrea_fee = Number(arrea_fee);
          }
          this.$axios
            .post(`${this.domain}/api/jinhua/road_pay`, {
              appId,
              itemId,
              mchNo: this.parkData.orderId,
              clientIp: "127.0.0.1",
              totalFee: this.cash,
              parkRecordId: this.parkData.parkRecordId,
              plateNo: this.parkData.plateNo,
              nos,
              arrearFee: arrea_fee || 0,
              method: openId ? 1 : 2,
              realNos,
              realFee,
              pdaNo,
            })
            .then((res) => {
              this.loading = false;
              window.location.href = res.data.data;
            });
        } else {
          if (openId) {
            this.wxSettlement();
          }
          if (userId) {
            this.aliSettlement();
          }
        }
      });
    },
    // 结算按钮
    payWaysettlement: _.debounce(function () {
      this.$toast.loading({
        duration: 0,
        message: "支付中...",
        forbidClick: true,
        loadingType: "spinner",
      });
      let parkRecordId = localStorage.getItem("parkRecordId");
      this.$axios
        .post(`${this.domain}/api/charge/charging`, {
          parkRecordId,
        })
        .then((res) => {
          this.parkData = res.data.data;
          this.paymentAmount = res.data.data.shouldPay;
          this.savePaymentAmount = res.data.data.shouldPay;
          this.cash = res.data.data.shouldPay;

          let itemId = localStorage.getItem("itemId");
          let couponRecordId;
          let startTimeRecord;
          let stopTimeRecord;
          let monthFeeRecord;
          if (res.data.data.realParkRecord !== null) {
            // 处理泊位号
            res.data.data.realParkRecord.berthNos = JSON.parse(res.data.data.realParkRecord.berthNos).join();
            this.realParkRecord = res.data.data.realParkRecord;
            couponRecordId = res.data.data.realParkRecord.parkId;
            startTimeRecord = res.data.data.realParkRecord.startTime;
            stopTimeRecord = res.data.data.realParkRecord.endTime;
            monthFeeRecord = res.data.data.realParkRecord.shouldPay;
          } else {
            couponRecordId = parkRecordId;
            startTimeRecord = res.data.data.startTime;
            stopTimeRecord = res.data.data.stopTime;
            monthFeeRecord = res.data.data.shouldPay;
          }
          // 是否为全选，重新勾选展示
          if (this.checkAll) {
            this.handleCheckAllChange(true);
          }
          // 重新查询优惠券
          this.getCouponList({
            appId: res.data.data.appId,
            itemId,
            parkRecordId: couponRecordId,
            plateNo: res.data.data.plateNo,
            startTime: startTimeRecord,
            endTime: stopTimeRecord,
            monthFee: monthFeeRecord,
            scanningTime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
            type: 1,
          });
        })
        .then(() => {
          let openId = localStorage.getItem("openId");
          let userId = localStorage.getItem("alipayuserId");
          let parkId = this.realParkRecord.parkId ? this.realParkRecord.parkId : this.parkData.parkRecordId;

          // 判断是否是扫昨天的小票产生有实时订单
          if (this.realParkRecord.parkId) {
            // 判断是否是有金额
            if (this.shouldPayPreCPU == 0) {
              // 是否有勾选实时订单
              if (this.realParkRecordCheck.length) {
                // 判断是否是勾选了优惠券后变成了0元
                if (this.checkCouponList.id) {
                  this.payRequest();
                } else {
                  this.$axios.get(`${this.domain}/api/charge/zeroPayConfig?parkId=${parkId}`).then((res) => {
                    // 是否开启0元支付
                    if (res.data.data && this.realParkRecord.amount == 0) {
                      this.$toast.clear();
                      Dialog.confirm({
                        message: "是否确认离场?",
                      })
                        .then(() => {
                          this.$axios
                            .get(`${this.domain}/api/charge/freeTimePaymentOf0Yuan?parkId=${parkId}`)
                            .then((res) => {
                              if (openId) {
                                WeixinJSBridge.call("closeWindow");
                              }
                              if (userId) {
                                AlipayJSBridge.call("closeWebview");
                              }
                            })
                            .catch((e) => {
                              this.$toast.clear();
                              Dialog.alert({
                                message: `离场失败，请联系客服`,
                              }).then(() => {
                                // on close
                              });
                            });
                        })
                        .catch(() => {
                          // on cancel
                        });
                    } else {
                      this.$toast.clear();
                      Dialog.alert({
                        message: `${res.data.msg}`,
                      }).then(() => {
                        // on close
                      });
                    }
                  });
                }
              } else {
                this.$toast.clear();
                Dialog.alert({
                  message: "当前订单金额为0元无需支付,请联系客服出车",
                }).then(() => {
                  // on close
                });
              }
            } else {
              // 是否勾选实时订单
              if (this.realParkRecordCheck.length) {
                // 实时订单金额是否为0元
                if (this.realParkRecordCheck[0].shouldPay != 0) {
                  this.payRequest();
                  // 为0元判断
                } else {
                  // 判断实时订单是否是勾选了优惠券之后变成0元
                  if (this.checkCouponList.id) {
                    this.payRequest();
                  } else {
                    this.$toast.clear();
                    this.$axios.get(`${this.domain}/api/charge/zeroPayConfig?parkId=${parkId}`).then((res) => {
                      // 是否开启0元支付功能
                      if (res.data.data && this.realParkRecord.shouldPay == 0) {
                        // 实时订单为0元，其他金额也要支付，同时处理
                        this.$axios.get(`${this.domain}/api/charge/freeTimePaymentOf0Yuan?parkId=${parkId}`);
                        this.payRequest();
                      } else {
                        this.$toast.clear();
                        Dialog.alert({
                          message: "当前实时订单不可支付，请取消勾选后重新支付",
                        }).then(() => {
                          // on close
                        });
                      }
                    });
                  }
                }
                // 不勾选实时订单有金额直接支付
              } else {
                this.payRequest();
              }
            }
            // 扫的是当天当前的小票
          } else {
            // 判断是否是有金额
            if (this.shouldPayPreCPU == 0) {
              // 判断是否是选中优惠券之后的0元
              if (this.checkCouponList.id) {
                this.payRequest();
              } else {
                this.$axios.get(`${this.domain}/api/charge/zeroPayConfig?parkId=${parkId}`).then((res) => {
                  // 是否开启0元支付
                  if (res.data.data && this.parkData.totalPay === 0) {
                    this.$toast.clear();
                    Dialog.confirm({
                      message: "是否确认离场?",
                    })
                      .then(() => {
                        this.$axios
                          .get(`${this.domain}/api/charge/freeTimePaymentOf0Yuan?parkId=${parkId}`)
                          .then((res) => {
                            if (openId) {
                              WeixinJSBridge.call("closeWindow");
                            }
                            if (userId) {
                              AlipayJSBridge.call("closeWebview");
                            }
                          })
                          .catch((e) => {
                            this.$toast.clear();
                            Dialog.alert({
                              message: `离场失败，请联系客服`,
                            }).then(() => {
                              // on close
                            });
                          });
                      })
                      .catch(() => {
                        // on cancel
                      });
                  } else {
                    this.$toast.clear();
                    Dialog.alert({
                      message: `${res.data.msg}`,
                    }).then(() => {
                      // on close
                    });
                  }
                });
              }
              // 有支付金额
            } else {
              this.$axios.get(`${this.domain}/api/charge/zeroPayConfig?parkId=${parkId}`).then((res) => {
                // 是否开启0元支付
                if (res.data.data) {
                  this.payRequest();
                  // 判断当前订单金额是否为0元，为0则一起0元支付出车
                  if (this.parkData.totalPay === 0) {
                    this.$axios.get(`${this.domain}/api/charge/freeTimePaymentOf0Yuan?parkId=${parkId}`);
                  }
                } else {
                  this.payRequest();
                }
              });
            }
          }
        })
        .catch((e) => {
          this.$toast.clear();
        });
    }, 100),

    // 支付宝结算
    aliSettlement() {
      let userId = localStorage.getItem("alipayuserId");
      let itemId = localStorage.getItem("itemId");
      let pdaNo = localStorage.getItem("pdaNo");
      let ids = [];
      let nos = "";
      let arrea_fee = null;
      if (this.checkedPark.length) {
        this.checkedPark.forEach((item) => {
          ids.push(item.id);
        });
        nos = ids.join(",");
        arrea_fee = this.checkedPark.reduce((accumulator, currentValue) => {
          const valueToAdd = currentValue.arrear;
          return math.add(math.bignumber(accumulator), math.bignumber(valueToAdd));
        }, 0);
        arrea_fee = Number(arrea_fee);
      }
      let realFee = this.realParkRecordCheck.length
        ? this.checkCouponList.id
          ? this.checkCouponList.afterFee
          : this.realParkRecordCheck[0].shouldPay
        : 0;
      let realNos =
        this.realParkRecordCheck.length && this.realParkRecordCheck[0].shouldPay !== 0
          ? this.realParkRecordCheck[0].parkId
          : null;
      let lastFee = this.checkCouponList.id ? this.checkCouponList.lastFee : 0;
      let ticketId = this.checkCouponList.id ? this.checkCouponList.id : null;
      // 使用优惠券之后是否为0元
      let isTicketZero = this.shouldPayPreCPU == 0 && this.checkCouponList.afterFee == 0 ? "kun" : null;
      if (this.parkData.plateNo) {
        this.$axios
          .post(`${this.domain}/api/pay/aliPay`, {
            appId: this.parkData.appId,
            itemId,
            ifCode: "alipay",
            mchNo: this.parkData.orderId,
            userId,
            body: "停车缴费",
            notify_url: "http://www.baidu.com",
            clientIp: "127.0.0.1",
            totalFee: this.cash,
            parkRecordId: this.parkData.parkRecordId,
            plate_no: this.parkData.plateNo,
            nos,
            arrearFee: arrea_fee || 0,
            realFee,
            realNos,
            ticketId,
            lastFee,
            isTicketZero,
            pdaNo,
          })
          .then((res) => {
            this.$toast.clear();
            if (res.data.code === 200) {
              if (isTicketZero == null) {
                this.tradePay(`${res.data.trade_no}`);
              } else {
                Dialog.alert({
                  message: "当前订单已支付",
                }).then(() => {
                  AlipayJSBridge.call("closeWebview");
                });
              }
            } else {
              Dialog.confirm({
                message: `${res.data.msg}`,
              })
                .then(() => {
                  this.$toast.clear();
                })
                .catch(() => {
                  // on cancel
                });
            }
          })
          .catch((error) => {
            this.$toast.clear();
            alert(error);
          });
      } else {
        this.$axios
          .post(`${this.domain}/api/brake/unifierOrder`, {
            appId: this.parkData.appId,
            itemId,
            openId: userId,
            money: this.cash,
            parkId: this.parkData.parkRecordId,
            payCode: "alipay",
          })
          .then((res) => {
            this.$toast.clear();
            let data = res.data.data.trade_no;
            this.tradePay(data);
          })
          .catch((e) => {
            this.$toast.clear();
          });
      }
    },
    // 支付宝收银台
    // 由于js的载入是异步的，所以可以通过该方法，当AlipayJSBridgeReady事件发生后，再执行callback方法
    ready(callback) {
      if (window.AlipayJSBridge) {
        callback && callback();
      } else {
        document.addEventListener("AlipayJSBridgeReady", callback, false);
      }
    },
    tradePay(tradeNO) {
      this.ready(() => {
        // 通过传入交易号唤起快捷调用方式(注意tradeNO大小写严格)
        AlipayJSBridge.call(
          "tradePay",
          {
            tradeNO: tradeNO,
          },
          (data) => {
            if (data.resultCode === "9000") {
              AlipayJSBridge.call("closeWebview");
            } else if (data.resultCode === "6001") {
              let parkRecordId = localStorage.getItem("parkRecordId");
              let payRecordIds =
                parkRecordId +
                (this.realParkRecordCheck.length ? "," + this.realParkRecordCheck[0].parkId : "") +
                (this.checkedPark.length ? "," + this.checkedPark.map((item) => item.parkRecordId).join(",") : "");
              this.$axios.post(`${this.domain}/api/pay/cancelRoadCharge`, {
                parkRecordId,
                realRecordId: this.realParkRecordCheck.length ? this.realParkRecordCheck[0].parkId : null,
                payRecordIds,
              });
              alert("支付已取消，请重新扫码");
            } else {
              alert("支付失败，请重新扫码");
            }
          }
        );
      });
    },
    // 微信结算
    wxSettlement() {
      let openId = localStorage.getItem("openId");
      let itemId = localStorage.getItem("itemId");
      let pdaNo = localStorage.getItem("pdaNo");
      let ids = [];
      let nos = "";
      let arrea_fee = null;
      // 计算欠费
      if (this.checkedPark.length) {
        this.checkedPark.forEach((item) => {
          ids.push(item.id);
        });
        nos = ids.join(",");
        arrea_fee = this.checkedPark.reduce((accumulator, currentValue) => {
          const valueToAdd = currentValue.arrear;
          return math.add(math.bignumber(accumulator), math.bignumber(valueToAdd));
        }, 0);
        arrea_fee = Number(arrea_fee);
      }
      // 支付宝一样
      // 实时订单金额 this.realParkRecordCheck[0].shouldPay : 0;
      let realFee = this.realParkRecordCheck.length
        ? this.checkCouponList.id
          ? this.checkCouponList.afterFee
          : this.realParkRecordCheck[0].shouldPay
        : 0;
      // 实时订单单号
      let realNos =
        this.realParkRecordCheck.length && this.realParkRecordCheck[0].shouldPay !== 0
          ? this.realParkRecordCheck[0].parkId
          : null;
      // 优惠券抵扣金额
      let lastFee = this.checkCouponList.id ? this.checkCouponList.lastFee : 0;
      // 优惠券id
      let ticketId = this.checkCouponList.id ? this.checkCouponList.id : null;
      // 使用优惠券之后是否为0元
      let isTicketZero = this.shouldPayPreCPU == 0 && this.checkCouponList.afterFee == 0 ? "kun" : null;
      if (this.parkData.plateNo) {
        this.$axios
          .post(`${this.domain}/api/pay/unifierOrder`, {
            appId: this.parkData.appId,
            itemId,
            ifCode: "wxpay",
            mchNo: this.parkData.orderId,
            openId,
            body: "停车缴费",
            notify_url: "http://www.baidu.com",
            clientIp: "127.0.0.1",
            totalFee: this.cash,
            parkRecordId: this.parkData.parkRecordId,
            plate_no: this.parkData.plateNo,
            nos,
            arrearFee: arrea_fee || 0,
            realFee,
            realNos,
            ticketId,
            lastFee,
            isTicketZero,
            pdaNo,
          })
          .then((res) => {
            this.$toast.clear();
            if (res.data.code === 200) {
              if (isTicketZero == null) {
                let data = res.data.data;
                this.weixinPay(data);
              } else {
                Dialog.alert({
                  message: "当前订单已支付",
                }).then(() => {
                  WeixinJSBridge.call("closeWindow");
                });
              }
            } else {
              Dialog.confirm({
                message: `${res.data.msg}`,
              })
                .then(() => {
                  this.$toast.clear();
                })
                .catch(() => {
                  // on cancel
                });
            }
          })
          .catch((error) => {
            this.$toast.clear();
            alert(error);
          });
      } else {
        this.$axios
          .post(`${this.domain}/api/brake/unifierOrder`, {
            appId: this.parkData.appId,
            itemId,
            openId,
            money: this.cash,
            parkId: this.parkData.parkRecordId,
            payCode: "wechat",
          })
          .then((res) => {
            this.$toast.clear();
            let data = res.data.data;
            this.weixinPay(data);
          })
          .catch((e) => {
            this.$toast.clear();
          });
      }
    },
    // 微信收银台
    //解决微信内置对象报错
    weixinPay(data) {
      var that = this;
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener("WeixinJSBridgeReady", that.onBridgeReady(data), false);
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", that.onBridgeReady(data));
          document.attachEvent("onWeixinJSBridgeReady", that.onBridgeReady(data));
        }
      } else {
        that.onBridgeReady(data);
      }
    },
    // 微信内置浏览器类，weChatParameter对象中的参数是3.步骤代码中从后端获取的数据
    onBridgeReady(data) {
      WeixinJSBridge.invoke("getBrandWCPayRequest", data, (res) => {
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          this.$router.push("/inpark/success");
        } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
          let parkRecordId = localStorage.getItem("parkRecordId");
          let payRecordIds =
            parkRecordId +
            (this.realParkRecordCheck.length ? "," + this.realParkRecordCheck[0].parkId : "") +
            (this.checkedPark.length ? "," + this.checkedPark.map((item) => item.parkRecordId).join(",") : "");
          this.$axios.post(`${this.domain}/api/pay/cancelRoadCharge`, {
            parkRecordId,
            realRecordId: this.realParkRecordCheck.length ? this.realParkRecordCheck[0].parkId : null,
            payRecordIds,
          });
          alert("支付已取消，请重新扫码");
        } else {
          this.$router.push("/inpark/fail");
        }
      });
    },
    // 详情
    toDepDetail(id) {
      this.$router.push({
        path: "/inpark/deepdetails",
        query: { id },
      });
    },
    // 跳转
    toDetail() {
      this.detailsedit = !this.detailsedit;
    },
  },
};
</script>

<style lang="scss" scoped>
html,
body {
  width: 100%;
  height: 100%;
}
.parking {
  width: 100%;
  height: 100%;
  position: absolute;

  .parkingpay {
    // position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ccc;
    overflow: auto;
    padding-bottom: 50px;

    .license-plate {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px auto;
      width: 200px;
      height: 70px;
      border-radius: 5px;
      background-color: #4395ff;

      .number {
        width: 190px;
        height: 60px;
        line-height: 60px;
        border-radius: 5px;
        text-align: center;
        line-height: 60px;
        font-size: 28px;
        color: white;
        border: 1px solid white;
      }
    }
    .form:nth-child(2) {
      margin-bottom: 60px;
    }
    .box-card {
      margin: 5px 10px;

      .clearfix {
        font-size: 16px;
      }

      .details {
        .cash {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          color: red;
          font-size: 14px;
        }

        .etc {
          width: 100%;
          margin-bottom: 10px;
          text-align: center;
          color: rgb(238, 164, 5);
          font-size: 14px;
        }

        .message {
          display: flex;
          justify-content: space-between;
          margin: 5px 0;
          font-size: 14px;
        }
      }

      .box-card-de {
        padding: 10px;
        background-color: #f3f1f1;
        margin-top: 20px;

        .clearfix-de {
          font-size: 16px;
          border-bottom: 1px solid #ccc;
          padding-bottom: 10px;
          margin-bottom: 10px;
        }

        .text-de {
          font-size: 14px;
        }
      }
    }

    .arrears {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      padding-bottom: 10px;
      border-bottom: 1px solid #ccc;
    }

    .line {
      margin: 10px auto;
      width: 310px;
      height: 1px;
      background-color: #ccc;
    }

    .coupon {
      z-index: 99;
      position: fixed;
      bottom: 50px;
      left: 0;
      right: 0;
    }

    .coupon-box {
      box-sizing: border-box;
      padding: 13px 10px 50px 10px;
      overflow-y: hidden;

      .coupon-title {
        display: flex;
        justify-content: center;
        font-size: 18px;
      }
      .coupontype-box {
        box-sizing: border-box;
        height: 366px;
        padding-bottom: 100px;
        overflow-y: scroll;
        .coupontype {
          display: flex;
          position: relative;
          margin-bottom: 10px;
          border-radius: 5px;
          box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);

          .left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 80px;
            height: 80px;
            background: linear-gradient(90deg, rgba(254, 213, 134, 1) 1.251%, rgba(255, 157, 77, 1) 100%);
            border-radius: 5px 0px 0px 5px;

            .couponname {
              width: 48px;
              height: 20px;
              line-height: 20px;
              background: rgb(250, 160, 97);
              border-radius: 3px;
              padding: 0px 6px 0px 6px;
              font-size: 12px;
              color: #ffffff;
              text-align: center;
            }
          }

          .right {
            position: relative;
            box-sizing: border-box;
            padding: 10px;
            width: 265px;
            height: 80px;
            background: rgb(255, 255, 255);
            border-radius: 0px 5px 5px 0px;
            line-height: 20px;

            .top-cotent {
              display: flex;
              justify-content: space-between;
              .rule {
                display: flex;
                align-items: center;
                font-size: 13px;
                color: #a3a3a3;

                .ruleimg {
                  margin-left: 5px;
                  width: 5px;
                  height: 9px;
                  background-image: url("../../assets/coupon-.png");
                  background-repeat: repeat;
                  background-position: none;
                }
              }
            }

            .checkbox {
              display: flex;
              justify-content: right;
            }

            .usetime {
              position: absolute;
              bottom: 20px;
              font-size: 12px;
              color: #a3a3a3;
            }

            .usetime2 {
              position: absolute;
              bottom: 5px;
              font-size: 12px;
              color: #a3a3a3;
            }
          }
        }
      }

      .coupon-define {
        position: fixed;
        bottom: 10px;
        left: 10px;
        right: 10px;
        background: linear-gradient(180deg, rgba(255, 167, 99, 1), rgba(250, 114, 12, 1) 100%);
        /* 橙色阴影 */
        box-shadow: 0px 2px 10px rgba(250, 114, 12, 0.4);
        font-size: 15px;
        color: #fff;
        cursor: pointer;
        border-radius: 22px;
        height: 44px;
        line-height: 44px;
        font-size: 15px;
        text-align: center;
      }
    }

    .tab-bar {
      z-index: 99;
      position: fixed;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 10px;
      width: 100%;
      height: 50px;
      background-color: white;
      font-size: 14px;

      button {
        border: none;
        width: 100px;
        height: 50px;
        color: white;
      }
    }
  }
}
</style>
